<template>
  <b-card class="card-company-table">
    <h4 class="mb-0">
        Transactions
      </h4>
    <table class="table">
      <thead>
            <tr>
              <th scope="col" v-for="item in fields" :key="item.key">
                {{item.label}}</th>
            </tr>
          </thead>
      <tr v-for="(item, index) in data" :key="index">
        <th>{{ item.userId }}</th>
        <td>{{ item.userName }}</td>
        <td>{{ item.userAmount }}</td>
        <td>{{ item.currency }}</td>
        <td v-if="item.status == 'Rejected'" class="table-display-tx reject">
          {{ item.status }}</td>
        <td v-if="item.status == 'Success'" class="table-display-tx success">
          {{ item.status }}</td>
        <td v-if="item.status == 'Pending'" class="table-display-tx pending">
          {{ item.status }}</td>
      </tr>
    </table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      // fields: [
      //   { key: 'company', label: 'COMPANY' },
      //   { key: 'category', label: 'CATEGORY' },
      //   { key: 'views', label: 'VIEWS' },
      //   { key: 'revenue', label: 'REVENUE' },
      //   { key: 'sales', label: 'SALES' },
      // ],
      fields: [
        { key: "userId", label: "USER ID" },
        { key: "userName", label: "USER NAME" },
        { key: "amount", label: "AMOUNT" },
        { key: "currency", label: "CURRENCY" },
        { key: "status", label: "STATUS" },
      ],
      data: [
        {
          userId: 34232 ,
          userName: "Mary Jane",
          userPhone: "+237668974011",
          userAmount: 12000,
          currency: "XAF",
          recepientAccountType: "Mobile Money",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Outgoing",
          status: "Success",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },
        {
          userId: 52232 ,
          userName: "John Smith",
          userPhone: "+237668974101",
          userAmount: 1500000,
          currency: "USD",
          recepientAccountType: "Credit Card",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Android Server",
          transactionType: "Incoming",
          status: "Success",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },
        {
          userId: 64232 ,
          userName: "Timothy Lucas",
          userPhone: "+23767897411",
          userAmount: 5000,
          currency: "XAF",
          recepientAccountType: "Mobile Money",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Outgoing",
          status: "Pending",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },
        {
          userId: 78932 ,
          userName: "Illaoi Bob",
          userPhone: "+23760897411",
          userAmount: 1000,
          currency: "XAF",
          recepientAccountType: "Mobile Money",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Top Up",
          status: "Success",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },
        {
          userId: 34932 ,
          userName: "Mba Rufus",
          userPhone: "+23762897411",
          userAmount: 1000,
          currency: "XAF",
          recepientAccountType: "Credit Card",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Top Up",
          status: "Success",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },
        {
          userId: 12932 ,
          userName: "Gabriel Bob",
          userPhone: "+23766897411",
          userAmount: 1000,
          currency: "XAF",
          recepientAccountType: "Debit Card",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Outgoing",
          status: "Success",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },{
          userId: 65932 ,
          userName: "Fongang Ryze",
          userPhone: "+23766897411",
          userAmount: 1000,
          currency: "XAF",
          recepientAccountType: "Mobile Money",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Incoming",
          status: "Pending",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },{
          userId: 34932 ,
          userName: "Mba Bob",
          userPhone: "+23766897411",
          userAmount: 1000,
          currency: "XAF",
          recepientAccountType: "Mobile Money",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Outgoing",
          status: "Rejected",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },{
          userId: 84932 ,
          userName: "Nfor Bob",
          userPhone: "+23766897411",
          userAmount: 1000,
          currency: "XAF",
          recepientAccountType: "Mobile Money",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Incoming",
          status: "Pending",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },{
          userId: 44932 ,
          userName: "Mba Karyn",
          userPhone: "+23766897411",
          userAmount: 1000,
          currency: "XAF",
           recepientAccountType: "Mobile Money",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Outgoing",
          status: "Success",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },{
          userId: 24932 ,
          userName: "Teemo Bob",
          userPhone: "+23766897411",
          userAmount: 1000,
          currency: "XAF",
          recepientAccountType: "Mobile Money",
          recipientAccountNumber: "65654863324",
          recipientAccountName: "Ojong Peter Pius",
          transactionType: "Outgoing",
          status: "Success",
          date: "03/12/2021",
          paymentMethod: "Credit Card",
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
